<template>
  <el-dialog
    custom-class="select-identity-dialog"
    title="请选择您的身份"
    :visible.sync="$_visible"
    width="740px"
    @close="onDialogClose">
    <div class="dialog_main">
      <div
        class="item_box"
        v-for="item in options"
        :key="item.id"
        @click="onSelect(item.id)">
        <h1>{{ item.title }}</h1>
        <p>{{ item.des }}</p>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data () {
    return {
      // 选项列表
      options: [
        {
          id: 0,
          title: '非法人组织入驻',
          des: '团队或合伙组织等非法人组织入驻申请'
        },
        {
          id: 1,
          title: '法人组织入驻',
          des: '填写机构相关信息进行入驻申请，需上传统一社会信用代码证'
        },
        {
          id: 2,
          title: '个人挂靠',
          des: '填写个人信息申请成为服务用户，可选择已入驻机构进行挂靠'
        }
      ]
    }
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  methods: {
    // 选择某一项
    onSelect (value) {
      this.$_visible = false
      switch (value) {
        case 0:
          // 选择非法人 进入非法人填报界面
          // window.open('/org-audit/uncorporate/apply', '_blank')
          this.$router.push('/org-audit/uncorporate/apply')
          break
        case 1:
          // 选择法人进入 法人填报界面
          // window.open('/org-audit/corporate/apply', '_blank')
          this.$router.push('/org-audit/corporate/apply')
          break
        case 2:
          // window.open('/org-audit/person/apply', '_blank')
          this.$router.push('/org-audit/person/apply')
          break
      }
    },
    // 关闭新建里程碑的dialog
    closeDialog () {
      this.$_visible = false
    },
    // dialog关闭事件
    onDialogClose () {
      this.$emit('onDialogClose')
    }
  }
}
</script>
<style lang="scss" scoped>
  .select-identity-dialog {
    .dialog_main {
      padding-left: 20px;
      background-image: url('../../assets/images/personal-info/select-identity.png');
      background-position: center right;
      background-repeat: no-repeat;
      .item_box {
        width: 436px;
        height: 100px;
        background: #FFFFFF;
        border: 1px solid #DCDFE6;
        border-radius: 6px;
        padding: 0 20px 20px 20px;
        color: #000000;
        cursor: pointer;
        & > h1 {
          font-size: 20px;
          font-weight: bold;
          line-height: 60px;
        }
        & > p {
          font-size: 14px;
          line-height: 20px;
          color: #606266;
        }
        &:hover {
          border-color: #3473E6;
          color: #3473E6;
        }
        &+.item_box {
          margin-top: 20px;
        }
      }
    }
  }
</style>

<style lang="scss">
  .select-identity-dialog {
    .el-dialog__header {
      padding-bottom: 16px;
      .el-dialog__title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
      }
    }
    .el-dialog__body {
      padding: 20px 20px 40px 20px;
    }
  }
</style>
