<!--
  非法人组织入驻 详情
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="非法人组织入驻申请详情" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <info-header title="机构信息" />
        <div style="padding:20px 0;">
          <info-item
            :label-width="labelWidth"
            label="机构Logo"
            value-type="image"
            :value="formData.avatarUrl">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="组织名称"
            :value="formData.organization.name">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="负责人姓名"
            :value="formData.organization.personCharge">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="行业领域"
            :value="getIndustrySectorNames(formData.serviceTrade)">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="产业特长"
            :value="getTechnicalNames(formData.industryExpertise)">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="服务方向"
            :value="formData.serviceDirection">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="人员情况"
            :value="educationSituation">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="机构服务案例"
            :value="formData.serviceCase">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="机构简介"
            :value="formData.organization.description">
          </info-item>
        </div>

        <info-header title="其他信息" />
        <div style="padding: 20px 0;">
          <info-item
            :label-width="labelWidth"
            label="联系人"
            :value="formData.username">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="性别"
            :value="{
              [globalVar.USER_GENDER_0]: '未知',
              [globalVar.USER_GENDER_1]: '男',
              [globalVar.USER_GENDER_2]: '女'
            }[formData.gender]">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="身份证号"
            :value="formData.idCard">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="职称/所获资格认证"
            :value="formData.positionalTitleText">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="最高学历"
            :value="formData.highestEducation">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="联系手机"
            :value="formData.contactNumber">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="联系邮箱"
            :value="formData.contactEmail">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="联系地址"
            :value="contactAddress">
          </info-item>
        </div>

        <!-- 被驳回 -->
        <template v-if="formData.registerStatus === globalVar.REGISTER_STATUS_REJECT">
          <info-header title="驳回信息" />
          <div style="padding: 20px 0;">
            <info-item
            :label-width="labelWidth"
            label="驳回原因"
            :value="formData.reason">
          </info-item>
          <!-- 已驳回状态下显示重新提交按钮 -->
          <el-row>
            <el-button
              type="primary"
              size="small"
              @click="selectIdentityVisible = true">
              重新申请
            </el-button>
          </el-row>
          </div>
        </template>
      </div>
    </div>

    <select-identity
      :visible.sync="selectIdentityVisible">
    </select-identity>
  </div>
</template>

<script>
import cities from '@/assets/json/cities.json'
import selectIdentity from '@/views/personal-info/select-identity.vue'
export default {
  components: { selectIdentity },
  data () {
    return {
      loading: false,
      // 机构入驻 -> 选择身份dialog
      selectIdentityVisible: false,
      labelWidth: '120px'
      // formData: {}
    }
  },
  computed: {
    formData () {
      return this.userInfo
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    // 获取技术领域name
    getTechnicalNames () {
      return (technical) => {
        const ids = technical ? technical.split(',') : []
        let result = ''

        this.technicalList.forEach(item => {
          if (ids.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    },
    // 行业领域下拉列表
    industrySectorList () {
      return this.$store.state.industrySectorList
    },
    // 拿到行业领域的id字符串
    getIndustrySectorNames () {
      return (idList = []) => {
        let result = ''

        this.industrySectorList.forEach(item => {
          if (idList.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    },
    // 人员情况
    educationSituation () {
      if (this.formData.organization.educationSituation) {
        const nums = this.formData.organization.educationSituation.split(',')
        return `总人数：${nums[0]}人，硕士（含以上）：${nums[1]}人，大学本科：${nums[2]}人，大专及（以下）：${nums[3]}人`
      } else {
        return ''
      }
    },
    // 联系地址
    contactAddress () {
      const { addressProvince, addressCity, addressCounty, contactAddress } = this.formData

      if (!addressProvince && !addressCity && !addressCounty) return ''

      const one = cities.find(item => {
        return item.value === addressProvince
      })
      if (!one) {
        return ''
      }
      const two = one.children.find(item => {
        return item.value === addressCity
      })
      if (!two) {
        return ''
      }
      const three = two.children.find(item => {
        return item.value === addressCounty
      })
      if (!three) {
        return ''
      }
      return `${one.label}、${two.label}、${three.label}、${contactAddress}`
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;
    }
  }
}
</style>
